import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import './global_theme.css';
import API_ENDPOINTS from './config'
function OverviewPage() {
    const email = localStorage.getItem('userEmail');
    const [userDetails, setUserDetails] = useState(null);
    const navigate = useNavigate();
    const [showLogoutDialog, setShowLogoutDialog] = useState(false); // Show/don't show logout modal
    const [showSidebar, setShowSidebar] = useState(false);
    // New state for tracking GPT API health
    const testStr = process.env.REACT_APP_TEST_STR
    const [isScalingIssue, setScalingIssue] = useState(false);
    // Function to check GPT API health
    const checkGPTServiceHealth = async () => {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 10000); // Timeout for 10 seconds

        try {
            const response = await fetch('https://api.openai.com/v1/chat/completions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${testStr}`
                },
                body: JSON.stringify({
                    model: "chatgpt-4o-latest",
                    messages: [{
                        role: "system",
                        content: "You are a helpful assistant."
                    }, {
                        role: "user",
                        content: "Hello, who are you?"
                    }]
                }),
                signal: controller.signal
            });

            // Check if the status code is in the range of success codes (200-299)
            if (response.ok) {
                setScalingIssue(false); // No scaling issue
            } else {
                // Handle HTTP error response
                const errorData = await response.json();
                console.error('API responded with an error:', errorData);
                setScalingIssue(true); // Assume scaling issue on HTTP error response
            }
        } catch (error) {
            console.error('Error when calling the API:', error);
            // Differentiate between a timeout and other errors
            if (error.name === 'AbortError') {
                console.error('API call timeout - potential scaling issue');
                setScalingIssue(true); // Handle a timeout as a potential scaling issue
            } else {
                console.error('General error when calling the API:', error);
                setScalingIssue(false); // For general errors, do not assume scaling issue
            }
        } finally {
            clearTimeout(timeoutId);
        }
    };
    /*
    * The below useEffect function will retrieve the logged-in users first and last
    * name from the MySql database based on the email used to log in.
    * */
    useEffect(() => {
         async function fetchUserDetails() {
             try {
                 const response = await  fetch(`${API_ENDPOINTS.fetchName}?email=${encodeURIComponent(email)}`, {
                     method: 'GET', mode: 'cors'});
                 const data = await response.json();

                 if (data.FirstName && data.LastName) {
                     setUserDetails(data);
                 }
                 else {
                     console.error('Failed to fetch user details.');
                 }
             } catch (error) {
                 console.error('Error fetching user details:', error);
             }
         }
         fetchUserDetails();
    }, [email]); // The effect depends on the email, so it's included in the dependency array

    // New useEffect for checking GPT API health on component mount
    useEffect(() => {
        checkGPTServiceHealth();
        // Set an interval to periodically check health
        const intervalId = setInterval(checkGPTServiceHealth, 60000); // Every 60 seconds

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    // Handler for clicking the Log-Out button
    function handleLogout() {
        localStorage.removeItem('authToken');
        navigate("/login");
    }
    // This handler navigates to the course overview for Python
    const handleCardPythonClick = () => {
        navigate("/python-overview")
    }
    // This handler navigates to the course overview for ReactJS
    const handleCardJSClick = () => {
        navigate("/essays-overview")
    }
    const handleCardAWSCCPClick = () => {
        navigate("/awsccp-overview")
    }
    const handleCardOracleClick = () => {
        navigate("/oracle-overview")
    }
    const handleShowGrades = () => {
        navigate("/scoring-overview")
    }
    return (
        <div className="main-content">
            {/* System Health Banner */}
            {isScalingIssue && (
                <div style={{ backgroundColor: 'orange', color: 'white', textAlign: 'center', padding: '10px' }}>
                    We're experiencing exceptionally high demand. Please hang tight as we work on scaling our systems.
                </div>
            )}
            {/* Sidebar Tab */}
            <div className="sidebar-tab" onClick={() => setShowSidebar(!showSidebar)}>
                <span className="arrow"></span>
            </div>
            {/* Sidebar */}
            <div className={`sidebar ${showSidebar ? 'expanded' : ''}`}
                 onMouseOver={() => setShowSidebar(true)}
                 onMouseOut={() => setShowSidebar(false)}>
                <div className="sidebar-content">
                    <p onClick={handleShowGrades}>Grades</p>
                    <p onClick={handleLogout}>Log Out</p>
                </div>
            </div>

            {/* Overlay */}
            <div className={`overlay ${showSidebar ? 'show' : ''}`} onClick={() => setShowSidebar(false)}></div>

            {/*The below code creates the modal that allows users to logout of their session*/}
            {/*Creating a header*/}
            <div className="header-spacer"></div>
            <div className="header-overview">Courses</div>
            <div className="header-line"></div>
            <div className="user-name" onClick={() => setShowLogoutDialog(true)}>
                <p>Logged in as: {userDetails && `${userDetails.FirstName} ${userDetails.LastName}`}</p>
            </div>

            <div className="container-overview">
                {/*Below are the cards that will link directly to the courses*/}
                <div className="card" onClick={handleCardPythonClick}>
                    <img src="/python.png" alt="Python"/>
                    <div className="para"><p>Introduction to Python</p></div>
                </div>
                <div className="card" onClick={handleCardAWSCCPClick}>
                    <img src="/AWS-CloudPractitioner-2020.png" alt="AWS-CCP"/>
                    <div className="para"><p>AWS CCP Practice</p></div>
                </div>
                <div className="card" onClick={handleCardOracleClick}>
                    <img src="/Oracle-Exam.jpeg" alt="Oracle-1Z0-071" />
                    <div className="para"><p>Oracle 1Z0-071 Practice</p></div>
                </div>
                <div className="card" onClick={handleCardJSClick}>
                    <img src="/Writing-essay.png" alt="Essays"/>
                    <div className="para"><p>GPT-Graded Essays</p></div>
                </div>
            </div>
        </div>
    );
}

export default OverviewPage;